import React from 'react';

import Layout from '../../components/layout/layout';
import Container from '../../components/container/container';

import * as styles from './terms-and-conditions.module.scss';

export default function TermsAndConditionsPage() {
  return (
    <Layout
      title="Terms & Conditions"
      className={styles.page}
      includeContactSection={false}
    >
      <Container>
        <h1 className="txt-primary">Terms &amp; Conditions</h1>
        <h4>Lorem ipsum dolor sit amet consectetur adipiscing elit.</h4>
        <p>
          Vivamus et convallis turpis, in auctor lorem. Nam eget posuere erat.
          Etiam vitae leo lacus. Nullam efficitur massa eget nisl interdum
          congue. Morbi nec consectetur purus. Donec dapibus luctus vestibulum.
          Proin finibus felis in risus interdum tempor. Sed auctor mi lacus, a
          varius erat molestie id. Integer felis ligula, sollicitudin vel orci
          vel, tincidunt sodales orci. Etiam vel lacinia ex. Donec commodo vel
          odio non mattis. Donec rhoncus quam eu suscipit tempus.
        </p>
      </Container>
    </Layout>
  );
}
